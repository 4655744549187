<template>
  <div>
    <!-- 充值300元抽奖 -->
    <div class="welfareBox">
      <div class="main">
        <choujiang></choujiang>
      </div>
    </div>
  </div>
</template>

<script>
import choujiang from "../common/choujiang.vue";
export default {
  name: "150cj",
  data() {
    return {
      a: 1
    };
  },
  components: {
    choujiang
  }
};
</script>

<style scoped lang="scss">
.ipt {
  height: 45px;
  width: 80%;
  border-radius: 15px;
  margin: 30px auto;
}

@media (max-width: 550px) {
  .welfareNav {
    width: 100%;
    margin: auto;
  }

  .ipt {
    height: 45px;
    width: 80%;
    border-radius: 15px;
    margin: 30px auto;
  }

  .title {
    font-size: 22px;
    margin: 25px auto;
  }
}
</style>